<template>
    <div class="align-items-start p-3 text-start">
        <nav aria-label="breadcrumb">
        <ol class="breadcrumb text-dark m-auto">
            <li class="breadcrumb-item active" aria-current="page">權限管理</li>
            <li class="breadcrumb-item active" aria-current="page">帳號管理</li>
            <li class="breadcrumb-item active" aria-current="page">編輯帳號</li>
        </ol>
        </nav>
        <hr class="w-100">
        <Form @submit="save()" :validation-schema="schema" @invalid-submit="on_invalid_submit">
            <div class="col-12 row mx-auto data-form">
                <div class="mb-3">
                    <label for="name" class="form-label"><span class="required" v-if="$route.name !== 'AccountView'">*</span>姓名</label>
                    <Field name="name" v-slot="{ meta, field }" v-model="account_data.name">
                        <input
                            id="name"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            :disabled="$route.name === 'AccountView'"
                        />
                    </Field>
                    <ErrorMessage name="name" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="account" class="form-label"><span class="required" v-if="$route.params.id === undefined && $route.name !== 'AccountView'">*</span>帳號</label>
                    <Field name="account" v-slot="{ meta, field }" v-model="account_data.account" :disabled="$route.params.id !== undefined">
                        <input
                            id="account"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            :disabled="$route.params.id !== undefined || $route.name === 'AccountView'"
                        />
                    </Field>
                    <ErrorMessage name="account" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="password" class="form-label"><span class="required" v-if="$route.params.id === undefined">*</span>密碼</label>
                    <div class="input-group">
                        <Field name="password" v-slot="{ meta, field }" v-model="password" v-on:change="clear_password_value()">
                            <input
                                id="password"
                                v-bind="field"
                                class="form-control"
                                :class="{
                                    'is-valid': meta.valid && meta.touched,
                                    'is-invalid': !meta.valid && meta.touched,
                                }"
                                :type="pwd_type"
                                placeholder="密碼"
                                autocomplete="on"
                                :disabled="$route.name === 'AccountView'"
                            />
                        </Field>
                        <button type="button" class="input-group-text btn-dark btn" v-on:click="show_pwd()">
                            <i class="bi bi-eye-fill"></i>
                        </button>
                        <ErrorMessage name="password" class="invalid-feedback"/>
                    </div>
                    <div id="password_help" class="form-text">8-20碼，包含大寫、小寫英文及數字</div>
                </div>
                <div class="mb-3">
                    <label for="location" class="form-label"><span class="required" v-if="$route.name !== 'AccountView'">*</span>地區</label>
                    <Field name="location" v-slot="{ meta, field }" v-model="account_data.location">
                        <select
                            id="location"
                            v-bind="field"
                            class="form-select"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            :disabled="$route.name === 'AccountView'"
                        >
                            <option value="" :disabled="true">請選擇</option>
                            <template v-for="item in location_option" :key="item">
                                <option :value="item.value">{{ item.text }}</option>
                            </template>
                        </select>
                    </Field>
                    <ErrorMessage name="location" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="role" class="form-label"><span class="required" v-if="$route.name !== 'AccountView'">*</span>帳號群組</label>
                    <Field name="role" v-slot="{ meta, field }" v-model="account_data.role_id">
                        <select
                            id="role"
                            v-bind="field"
                            class="form-select"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            :disabled="$route.name === 'AccountView'"
                        >
                            <option value="" :disabled="true">請選擇</option>
                            <template v-for="item in role_option" :key="item">
                                <option :value="item.value">{{ item.text }}</option>
                            </template>
                        </select>
                    </Field>
                    <ErrorMessage name="role" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label class="form-label"><span class="required" v-if="$route.name !== 'AccountView'">*</span>啟用狀態</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <Field name="status_options" v-slot="{ meta, field }" v-model="account_data.status">
                                <input
                                    id="status_radio_1"
                                    v-bind="field"
                                    class="form-check-input"
                                    :class="{
                                        'is-valid': meta.valid && meta.touched,
                                        'is-invalid': !meta.valid && meta.touched,
                                    }"
                                    type="radio"
                                    :value="1"
                                    :checked="account_data.status === 1"
                                    :disabled="$route.name === 'AccountView'"
                                />
                            </Field>
                            <label class="form-check-label" for="status_radio_1">啟用</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <Field name="status_options" v-slot="{ meta, field }" v-model="account_data.status">
                                <input
                                    id="status_radio_2"
                                    v-bind="field"
                                    class="form-check-input"
                                    :class="{
                                        'is-valid': meta.valid && meta.touched,
                                        'is-invalid': !meta.valid && meta.touched,
                                    }"
                                    type="radio"
                                    :value="0"
                                    :checked="account_data.status === 0"
                                    :disabled="$route.name === 'AccountView'"
                                />
                            </Field>
                            <label class="form-check-label" for="status_radio_2">停用</label>
                        </div>
                    </div>
                    <ErrorMessage name="status_options" class="radio-invalid-feedback"/>
                </div>
                <div class="mb-3 mx-auto text-center">
                    <button type="submit" class="btn m-2 btn-primary" :disabled="button_disabled" v-if="$route.name !== 'AccountView'">送出</button>
                    <button type="button" class="btn m-2 btn-danger" v-on:click="go_back()">返回</button>
                </div>
                <div class="mb-3">
                </div>
            </div>
        </Form>
    </div>
</template>

<script>
import { 
    get_account_detail,
    save_account_detail
} from '../../api/account';
import { mapActions, mapGetters } from 'vuex';
import { get_role_option } from '../../api/data';
import { Field, Form, ErrorMessage } from 'vee-validate';
import { 
    account_create_rules,
    account_modify_rules
} from '../../rules/admin/account';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';

export default {
    components: {
        Field,
        Form,
        ErrorMessage,
    },
    data() {
        return {
            account_data: {
                account: "",
                name: "",
                role_id: "",
                status: "",
                location: "",
            },
            password: "",
            location_option: [],
            role_option: [],
            button_disabled: false,
            pwd_type: "password",
            can_go_back: false,
        }
    },
    methods: {
        ...mapActions([
            'auth/UserLogout',
        ]),
        user_logout() {
            this['auth/UserLogout']().then(() => {
                this.$router.push({ name: 'Login' });
            }, () => {
                this.$router.push({ name: 'Login' });
            })
        },
        search() {
            get_account_detail({user_id: this.$route.params.id}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else if (response.data.success === false && response.data.error_code === "1002") {
                    this.$router.push({ name: 'AccountManagement' });
                } else if (response.data.success === false && response.data.error_code === "1003") {
                    this.$router.push({ name: 'AccountManagement' });
                } else {
                    this.account_data = response.data.response_data;
                }
            })
        },
        clear_password_value() {
            if (this.password == "" && this.$route.params.id !== undefined) {
                this.password = null;
            }
        },
        save() {
            this.button_disabled = true;
            let requset_data = JSON.parse(JSON.stringify(this.account_data));
            requset_data.password = JSON.parse(JSON.stringify(this.password));
            save_account_detail(requset_data).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else {
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: 'm-2 btn btn-primary',
                        },
                        buttonsStyling: false
                    })
                    swalWithBootstrapButtons.fire({
                        title: '提示訊息',
                        text: response.data.message,
                        icon: 'success',
                        showCloseButton: true,
                        allowOutsideClick: false,
                        confirmButtonText: '確認',
                    }).then((result) => {
                        if (result.isConfirmed || result.dismiss === "close") {
                            this.$router.push({ name: 'AccountManagement' });
                        }
                    })
                }
            }, error => {
                this.button_disabled = false;
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        cancelButton: 'm-2 btn btn-danger'
                    },
                    buttonsStyling: false
                })
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: error.response.data.message,
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            });
        },
        show_pwd() {
            if (this.pwd_type === 'password') {
                this.pwd_type = 'text';
            } else {
                this.pwd_type = 'password';
            }
        },
        on_invalid_submit() {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    cancelButton: 'm-2 btn btn-danger'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: '錯誤訊息',
                text: '尚有欄位未填寫或是填寫錯誤！',
                icon: 'error',
                cancelButtonText: '關閉',
                showCancelButton: true,
                showConfirmButton: false,
                showCloseButton: true,
                allowOutsideClick: false,
            });
        },
        go_back() {
            if (this.can_go_back === true) {
                this.$router.back();
            } else {
                this.$router.push({name: "AccountManagement"});
            }
        }
    },
    created() {
        window.scrollTo({top: 0, behavior: "smooth"});
        const router = useRouter();
        if (router.options.history.state.back !== null) {
            this.can_go_back = true;
        }
        if (this.$route.params.id !== undefined) {
            this.password = null;
            this.search();
        }
        get_role_option().then(response => {
            if (response.data.success === false && response.data.check === false) {
                this.user_logout();
            } else {
                this.role_option = response.data.response_data;
                this.location_option = response.data.response_data2;
            }
        })
    },
    computed: {
        schema() {
            if (this.$route.params.id !== undefined) {
                return account_modify_rules;
            } else {
                return account_create_rules;
            }
        },
        ...mapGetters({
            role_list: 'role_list'
        }),
    }
    
};
</script>
