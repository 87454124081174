import * as yup from 'yup';

export const account_create_rules = yup.object({
    name: yup.string().max(20, "姓名至多為20個字").required('姓名尚未填寫'),
    account: yup.string().required('帳號尚未填寫'),
    password: yup.string().matches(/^.*(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/, "密碼需包含大寫、小寫英文及數字").max(20, '密碼最多為20碼').min(8, '密碼至少為8碼').required('密碼尚未填寫'),
    role: yup.string().required('帳號群組尚未選擇'),
    location: yup.string().required('地區尚未選擇'),
    status_options: yup.string().required("啟用狀態尚未選擇"),
})

export const account_modify_rules = yup.object({
    name: yup.string().max(20, "姓名至多為20個字").required('姓名尚未填寫'),
    password: yup.string().matches(/^.*(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).*$/, "密碼需包含大寫、小寫英文及數字").max(20, '密碼最多為20碼').min(8, '密碼至少為8碼').nullable(),
    role: yup.string().required('帳號群組尚未選擇'),
    location: yup.string().required('地區尚未選擇'),
    status_options: yup.string().required("啟用狀態尚未選擇"),
})